import request from '@/utils/request'
export function getBannerList(query) {
  return request({
    url: '/business/lunbotu/list',
    method: 'get',
    params: query
  })
}
//
export function getLishushili(query) {
  return request({
    url: '/business/shili/list',
    method: 'get',
    params: query
  })
}
