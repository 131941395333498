<template>
    <el-backtop :bottom="100" class="backtop">
        <div class="backtop_inner" @click.stop>
        <div class="img_box">
            <img src="~@/assets/images/renwu_tu.png" alt="">
        </div>
        <div class="content">
            <div>
            <img src="~@/assets/images/gongzonghao.png" alt="">
            <p>公众号</p>
            </div>
            
            <el-popover
            placement="left"
            width="139"
            trigger="hover">
            <div class="back_pop">
                <div class="pop_box">
                <p class="pop_title">
                    <img src="~@/assets/images/phone.png" alt="">
                    <span>电话：</span>  
                </p>
                <p class="pop_text">0371-56062765</p>
                </div>
                <div class="pop_box">
                <p class="pop_title">
                    <img src="~@/assets/images/email.png" alt="">
                    <span>邮箱：</span>  
                </p>
                <p class="pop_text">mis01@qq.com</p>
                </div>
                <div class="pop_box">
                <p class="pop_title">
                    <img src="~@/assets/images/time.png" alt="">
                    <span>工作时间：</span>
                </p>
                <p class="pop_text">8:20-18:00</p>
                </div>
            </div>
            <div slot="reference">
                <img src="~@/assets/images/fuchuang_lianxi.png" alt="">
                <p>联系我们</p>
            </div>
            </el-popover>
            <div @click="backTop">
            <img src="~@/assets/images/backtop.png" alt="">
            </div>
        </div>
        </div>
    </el-backtop>
</template>

<script>
import {scrollTo} from '@/utils/scroll-to'
export default {
    methods:{
        backTop(){
            scrollTo(0,800)
        }
    }
}
</script>

<style lang="scss" scoped>
.backtop{
  height: 427px;
  width: 93px;
  border-radius: 0;
  background-color: transparent;
  box-shadow: none;
  border-radius: 47px;
  
  &:hover{
    background-color: transparent;
  }
  .backtop_inner{
    height: 100%;
    width: 100%;
    border-radius: 47px;
    position: relative;
    .img_box{
      position: absolute;
      top: 0;
      left: 0;
    }
    .content{
      height: 338px;
      background: linear-gradient(0deg, #0085E8, #42BEE5);
      box-shadow: 0px 2px 9px 0px rgba(39,39,39,0.11);
      border-radius: 47px;
      margin-top:89px;
      padding-top: 44px;
      padding-bottom: 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      img{
        margin: auto;
      }
      p{
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        text-align: center;
        margin-top: 8px;
      }
    }
  }
  .pop_box{
    color: #005FC6;
  }
}
</style>
<style lang="scss">
.back_pop{
  padding: 17px 7px;
  padding-bottom: 3px;
  .pop_box{
    margin-bottom: 14px;
    .pop_title{
      color: #005FC6;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      span{
        margin-left: 7px;
        line-height: 1;
      }
    }
    .pop_text{
      font-size: 14px;
      color: #333333;
    }
  }
}
  
</style>