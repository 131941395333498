<template>
  <div class="home">
    <Navbar :class="['nav_bar',isShow?'nav_down':'']" :style="{background: `rgba(5, 32, 63, ${scrollProgress})`}"></Navbar>
    <!-- <full-page ref="fullpage" :options="options"> -->
        <div class="section" style="position:relative;">
          <swiper v-if="bannerList.length>0" class="banner_swiper" :options="bannerOption">
            <swiper-slide v-for="(item,index) in bannerList" :key="index">
              <video class="video" loop autoplay muted v-if="item.pic.slice(item.pic.lastIndexOf('.') + 1)=='mp4'" :src="baseUrl+item.pic"></video>
              <div v-else class="section_first" :style="{backgroundImage: `url('${baseUrl+item.pic}')`}" ></div>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
            <!-- <div class="banner_btn button-prev" slot="button-prev">
            </div>
            <div class="banner_btn button-next" slot="button-next">
            </div>
              -->
          </swiper>
          <div class="banner-next" @click="handleNextPage">
            <span>
              <img class="mouse" src="~@/assets/images/mouse.png" alt="">
              <img class="arrows-1" src="~@/assets/images/arrow-1.png" alt="">
              <img class="arrows-2" src="~@/assets/images/arrow-2.png" alt="">
            </span>
          </div>
        </div>
        <div class="section module_home twos_content">
          <div class="inner">
              <div class="header">
                <h2>业务体系</h2>
                <p>BUSINESS SYSTEM</p>
              </div>
              <!--    -->
              <div class="box">
                <div class="two_content" @click.stop>
                  <div @click="handleTixiClick(0)" ref="tixi0" class="steam_ani">
                    <div class="countent_inner">
                      <img src="~@/assets/images/ywtx/1-1.png" alt="">
                      <p>杰文数字</p>
                    </div>
                    <div @click.stop :style="{left:tixiDolgPosition.tixi0+'px'}" :class="['two_dlog',isTixiActive===0?'tixi_active':'']">
                      <div class="inner_box">
                        <div>
                          <el-button type="text" icon="el-icon-back" @click="handleCloseTwoDlog"></el-button>
                        </div>
                        <div class="tow_dlogcontent" @click="handleCloseTwoDlog">
                          <div class="log_inner" @click.stop>
                            <div class="header">
                              <div class="title">杰文数字</div>
                              <div class="sub_title">深耕教育行业发展，引领数字教育创新模式</div>
                            </div>
                            <div class="text">数字教育是建立在知识层面之上、价值与能力并重的教育，发展目标是以教育数字化引领教育现代化，构建与数字时代相适应的全纳、公平、高质量、可持续的终身教育体系，培养全面化、自由化、个性化发展的数字时代新人。</div>
                            <div class="btn_box">
                              <a href="javascript:;" @click="handleShemeclick(0)">查看解决方案</a>
                              <a href="javascript:;"  @click="handleSuccessCases(0)">查看成功案例</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div @click="handleTixiClick(1)" ref="tixi1" class="steam_ani">
                    <div class="countent_inner">
                      <img src="~@/assets/images/ywtx/2-1.png" alt="">
                      <p>杰文智能</p>
                    </div>
                    <div @click.stop :style="{left:tixiDolgPosition.tixi1+'px'}" :class="['two_dlog',isTixiActive==1?'tixi_active':'']">
                      <div class="inner_box">
                        <div>
                          <el-button type="text" icon="el-icon-back" @click="handleCloseTwoDlog"></el-button>
                        </div>
                        <div class="tow_dlogcontent" @click="handleCloseTwoDlog">
                          <div class="log_inner" @click.stop>
                            <div class="header">
                              <div class="title">杰文智能</div>
                              <div class="sub_title">打造“智慧育人环境”</div>
                            </div>
                            <div class="text">杰文智能是面向教学、实验、科研、实训的综合实验室。辅助教学的新型创新式互动空间，深入贯彻落实《教育信息化2.0行动计划》相关思想，推进新时代教育信息化发展，并以“创新、智慧、空间”为设计理念，打造的新一代智慧教学空间。</div>
                            <div class="btn_box">
                              <a href="javascript:;" @click="handleShemeclick(1)">查看解决方案</a>
                              <a href="javascript:;"  @click="handleSuccessCases(1)">查看成功案例</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div @click="handleTixiClick(2)" ref="tixi2" class="steam_ani">
                    <div class="countent_inner">
                      <img src="~@/assets/images/ywtx/3-1.png" alt="">
                      <p>杰文仿真</p>
                    </div>
                    <div @click.stop :style="{left:tixiDolgPosition.tixi2+'px'}" :class="['two_dlog',isTixiActive==2?'tixi_active':'']">
                      <div class="inner_box">
                        <div>
                          <el-button type="text" icon="el-icon-back" @click="handleCloseTwoDlog"></el-button>
                        </div>
                        <div class="tow_dlogcontent" @click="handleCloseTwoDlog">
                          <div class="log_inner" @click.stop>
                            <div class="header">
                              <div class="title">杰文仿真</div>
                              <div class="sub_title">沉浸式教学新课堂</div>
                            </div>
                            <div class="text">虚拟仿真实训系统的建设在教育教学和职业发展中具有重要意义，有助于提高学生的综合素质和实践能力，促进教育创新和教育现代化进程。杰文仿真致力于打造沉浸式教学新课堂，以“沉浸式+互动式+情景式”的实验教学内容，让课堂更有趣，让教育更高效。</div>
                            <div class="btn_box">
                              <a href="javascript:;" @click="handleShemeclick(2)">查看解决方案</a>
                              <a href="javascript:;"  @click="handleSuccessCases(2)">查看成功案例</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div @click="handleTixiClick(3)" ref="tixi3" class="steam_ani">
                    <div class="countent_inner">
                      <img src="~@/assets/images/ywtx/4-1.png" alt="">
                      <p>杰文软件</p>
                    </div>
                    <div @click.stop :style="{left:tixiDolgPosition.tixi3+'px'}" :class="['two_dlog',isTixiActive==3?'tixi_active':'']">
                      <div class="inner_box">
                        <div>
                          <el-button type="text" icon="el-icon-back" @click="handleCloseTwoDlog"></el-button>
                        </div>
                        <div class="tow_dlogcontent" @click="handleCloseTwoDlog">
                          <div class="log_inner"  @click.stop>
                            <div class="header">
                              <div class="title">杰文软件</div>
                              <div class="sub_title">全过程数字化资源平台</div>
                            </div>
                            <div class="text">杰文软件基于高职院校智慧教务与资源库平台服务架构进行建设，打造课程学习、知识传播与管理分享于一体的一站式系统平台，杰文软件在功能上集合软件平台开发+可视化的应用场景进行高职院校智慧化概念构建，助力职业教育智慧化资源转型。</div>
                            <div class="btn_box">
                              <a href="javascript:;" @click="handleShemeclick(3)">查看解决方案</a>
                              <a href="javascript:;"  @click="handleSuccessCases(3)">查看成功案例</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div @click="handleTixiClick(4)" ref="tixi4" class="steam_ani">
                    <div class="countent_inner">
                      <img src="~@/assets/images/ywtx/5-1.png" alt="">
                      <p>杰文学院</p>
                    </div>
                    <div @click.stop :style="{left:tixiDolgPosition.tixi4+'px'}" :class="['two_dlog',isTixiActive==4?'tixi_active':'']">
                      <div class="inner_box">
                        <div>
                          <el-button type="text" icon="el-icon-back" @click="handleCloseTwoDlog"></el-button>
                        </div>
                        <div class="tow_dlogcontent"  @click="handleCloseTwoDlog">
                          <div class="log_inner" @click.stop>
                            <div class="header">
                              <div class="title">杰文学院</div>
                              <div class="sub_title">大赛学院+现代学徒制人才培养模式创新</div>
                            </div>
                            <div class="text">杰文学院坚持“以赛促研、以赛促教、以赛促学”的根本目标深入教育创新核心层，助力教学体系新生态的构建与实施，纵深推动高职教育的高质量建设，深化高水平人才培养体系，始终在"专业共建、课程共研、实践创新、人才共育"砥砺前行。</div>
                            <div class="btn_box">
                              <a href="javascript:;" @click="handleShemeclick(4)">查看解决方案</a>
                              <a href="javascript:;"  @click="handleSuccessCases(4)">查看成功案例</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <!-- ,'animate__animated',isShowDlog?'animate__zoomInDown':'animate__zoomOutDown' -->
                <img :class="['transition',isTixiActive===0?'transition_active':'']" src="~@/assets/images/gif/shuzibig.gif" alt="">
                <img :class="['transition',isTixiActive==1?'transition_active':'']" src="~@/assets/images/gif/zhinengbig.gif" alt="">
                <img :class="['transition',isTixiActive==2?'transition_active':'']" src="~@/assets/images/gif/fangzhenbig.gif" alt="">
                <img :class="['transition',isTixiActive==3?'transition_active':'']" src="~@/assets/images/gif/ruanjainbig.gif" alt="">
                <img :class="['transition','lastImg',isTixiActive==4?'transition_active':'']" src="~@/assets/images/huxi.png" alt="">
                <!-- <img v-if="isTixiActive==2" class="transition" src="~@/assets/images/gif/fangzhen.gif" alt=""> -->
              </div>
            </div>
        </div>
        <div class="section module_home three_content">
          <div class="inner">
            <div class="header">
              <h2>解决方案</h2>
              <p>TOTAL SOLUTION</p>
            </div>
            <ul class="three_content_inner">
              <li @click="handleShemeclick(0)" class="total_ani">
                <div class="top">
                  杰文数字
                  <br>
                  通用型解决方案
                </div>
                <div class="bottom">
                  <h2>杰文数字解决方案</h2>
                  <p>解决院校“金师、金专、金课”的资源拍摄与建设服务等问题，为数字教育创新提供解决方案。</p>
                </div>
              </li>
              <li @click="handleShemeclick(1)"  class="total_ani">
                <div class="top">
                  杰文智能
                  <br>
                  通用型解决方案
                </div>
                <div class="bottom">
                  <h2>杰文智能解决方案</h2>
                  <p>打造“智慧育人环境”为教师提升、教学改革、教学研讨与教学实践提供新一代智慧教学空间解决方案。</p>
                </div>
              </li>
              <li @click="handleShemeclick(2)"  class="total_ani">
                <div class="top">
                  杰文仿真
                  <br>
                  通用型解决方案
                </div>
                <div class="bottom">
                  <h2>杰文仿真解决方案</h2>
                  <p>以建设沉浸式教学新课堂为目标，提供“沉浸式+互动式+情景式”的实验教学内容解决方案。</p>
                </div>
              </li>
              <li @click="handleShemeclick(3)"  class="total_ani">
                <div class="top">
                  杰文软件
                  <br>
                  通用型解决方案
                </div>
                <div class="bottom">
                  <h2>杰文软件解决方案</h2>
                  <p>以高职院校智慧教务与资源库平台服务架构进行建设，提供集课程学习、知识传播与管理分享于一体的系统平台解决方案。</p>
                </div>
              </li>
              <li @click="handleShemeclick(4)"  class="total_ani">
                <div class="top">
                  杰文学院
                  <br>
                  通用型解决方案
                </div>
                <div class="bottom">
                  <h2>杰文学院解决方案</h2>
                  <p>为高职教育的高质量建设和人才培养，提供"专业共建、课程共研、实践创新、人才共育"的解决思路。</p>
                </div>
              </li>
              <li class="last total_ani">
                <div class="top">
                  <router-link to="/about-us">
                    联系我们
                    <br>
                    获取更多方案
                  </router-link>
                </div>
                <div class="bottom">
                  <h2>杰文数字解决方案</h2>
                  <p>解决院校“金师、金专、金课”的资源拍摄与建设服务等问题，为数字教育创新提供解决方案。</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="section module_home four_content">
          <div class="inner">
            <div class="header">
              <h2>成功案例</h2>
              <p>SUCCESS CASES</p>
            </div>
            <div class="four_content_inner">
              <div class="item left cases_ani">
                <div :class="['sub_item',rightActive==0?'left_active':'']" @click="handleFourClick(0)">
                  <div class="sub_inner">
                    <div class="image_box">
                      <img v-if="rightActive==0" src="~@/assets/images/ywtx/shuzi_lanse.png" alt="">
                      <img v-else src="~@/assets/images/ywtx/shuzi_baise.png" alt="">
                    </div>
                    <div>
                      <h4>杰文数字</h4>
                      <p>数字化教育资源建设服务案例 </p>
                    </div>
                  </div>
                </div>
                <div :class="['sub_item',rightActive==1?'left_active':'']" @click="handleFourClick(1)">
                  <div class="sub_inner">
                    <div class="image_box">
                      <!-- <img src="~@/assets/images/ywtx/2-1.png" alt=""> -->
                      <img v-if="rightActive==1" src="~@/assets/images/ywtx/zhinenng_lanse.png" alt="">
                      <img v-else src="~@/assets/images/ywtx/zhineng_baise.png" alt="">
                    </div>
                    <div>
                      <h4>杰文智能</h4>
                      <p>智慧空间建设服务案例 </p>
                    </div>
                  </div>
                </div>
                <div :class="['sub_item',rightActive==2?'left_active':'']" @click="handleFourClick(2)">
                  <div class="sub_inner">
                    <div class="image_box">
                      <!-- <img src="~@/assets/images/ywtx/3-1.png" alt=""> -->
                      <img v-if="rightActive==2" src="~@/assets/images/ywtx/fangzhen_lanse.png" alt="">
                      <img v-else src="~@/assets/images/ywtx/fangzhen_baise.png" alt="">
                    </div>
                    <div>
                      <h4>杰文仿真</h4>
                      <p>沉浸式资源建设服务案例 </p>
                    </div>
                  </div>
                </div>
                <div :class="['sub_item',rightActive==3?'left_active':'']" @click="handleFourClick(3)">
                  <div class="sub_inner">
                    <div class="image_box">
                      <!-- <img src="~@/assets/images/ywtx/4-1.png" alt=""> -->
                      <img v-if="rightActive==3" src="~@/assets/images/ywtx/ruanjian_lanse.png" alt="">
                      <img v-else src="~@/assets/images/ywtx/ruanjian_baise.png" alt="">
                    </div>
                    <div>
                      <h4>杰文软件</h4>
                      <p>教务平台建设服务案例 </p>
                    </div>
                  </div>
                </div>
                <div :class="['sub_item',rightActive==4?'left_active':'']" @click="handleFourClick(4)">
                  <div class="sub_inner">
                    <div class="image_box">
                      <!-- <img src="~@/assets/images/ywtx/5-1.png" alt=""> -->
                      <img v-if="rightActive==4" src="~@/assets/images/ywtx/xueyuan_lanse.png" alt="">
                      <img v-else src="~@/assets/images/ywtx/xueyuan_xueyuan.png" alt="">
                    </div>
                    <div>
                      <h4>杰文学院</h4>
                      <p>高职教育人才培养服务案例 </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="item right cases_ani">
                <div :class="['right_item',rightActive==0?'right_active':'']">
                  <h3>杰文数字</h3>
                  <p>为高校数字化教育内容建设服务12年，在国家级专业群、省级专业群、国家级资源库、省级资源库、国家级精品在线开放课、省级精品在线开放课程建设上，取得优异成绩。</p>
                  <a href="javascript:;" @click="handleSuccessCases(0)">了解更多 ></a>
                  <div class="logoList">
                    <div v-for="item in 12" :key="item">
                      <img style="width: 130px;height: 94px;" :src="require(`./../assets/school/${item}.png`)">
                    </div>
                  </div>
                </div>
                <div :class="['right_item',rightActive==1?'right_active':'']">
                  <h3>杰文智能</h3>
                  <p>为高校教师提升提供智慧化空间、为教学改革推动信息化技术、为教学研讨与教学实践提供新一代智慧教学实践场地，为高校的发展提供示范性案例。</p>
                  <a href="javascript:;" @click="handleSuccessCases(1)">了解更多 ></a>
                  <div class="logoList">
                    <div v-for="item in 12" :key="item">
                      <img style="width: 130px;height: 94px;" :src="require(`./../assets/school/${item}.png`)">
                    </div>
                  </div>
                </div>
                <div :class="['right_item',rightActive==2?'right_active':'']">
                  <h3>杰文仿真</h3>
                  <p>以“沉浸式+互动式+情景式”的实验教学内容，为医学、养老、机车、机电、新能源、物流、畜牧等数十个专业提供沉浸式教学新课堂示范性案例。</p>
                  <a href="javascript:;" @click="handleSuccessCases(2)">了解更多 ></a>
                  <div class="logoList">
                    <div v-for="item in 12" :key="item">
                      <img style="width: 130px;height: 94px;" :src="require(`./../assets/school/${item}.png`)">
                    </div>
                  </div>
                </div>
                <div :class="['right_item',rightActive==3?'right_active':'']">
                  <h3>杰文软件</h3>
                  <p>基于高职院校智慧教务与资源库平台服务架构进行建设，打造课程学习、知识传播与管理分享于一体的一站式系统平台示范性案例。</p>
                  <a href="javascript:;" @click="handleSuccessCases(3)">了解更多 ></a>
                  <div class="logoList">
                    <div v-for="item in 12" :key="item">
                      <img style="width: 130px;height: 94px;" :src="require(`./../assets/school/${item}.png`)">
                    </div>
                  </div>
                </div>
                <div :class="['right_item',rightActive==4?'right_active':'']">
                  <h3>杰文学院</h3>
                  <p>为高职教育的高质量建设、教师的高质量发展、人才的高质量培养，提供"专业共建、课程共研、实践创新、人才共育"的示范性案例。</p>
                  <a href="javascript:;" @click="handleSuccessCases(4)">了解更多 ></a>
                  <div class="logoList">
                    <div v-for="item in 12" :key="item">
                      <img style="width: 130px;height: 94px;" :src="require(`./../assets/school/${item}.png`)">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section module_home five_content">
          <div class="inner">
            <div class="header">
              <h2>企业动态</h2>
              <p>ENTERPRISE DYNAMICS</p>
            </div>
            <div class="five_content_inner">
              <div class="left">
                <div class="img_box company_ani">
                  <img src="~@/assets/images/ywtxone.png" alt="">
                </div>
                <div class="five_item company_ani" v-if="newsList.length>=1" @click="handleNewsItems(newsList[0].id)">
                  <h2 class="txt-line1">{{newsList[0].title}}</h2>
                  <p v-html="newsList[0].content"></p>
                </div>
              </div>
              <div class="right">
                <template v-for="(item,index) in newsList">
                  <div class="five_item company_ani" v-if="index>0"  :key="item.id" @click="handleNewsItems(item.id)">
                    <h2 class="txt-line1">{{item.title}}</h2>
                    <p v-html="newsList[0].content"></p>
                    <div class="footer">
                      <span>2023.03.5</span>
                      <a href="javascript:;">MORE <i></i> </a>
                    </div>
                  </div>
                </template>

                <!-- <div class="five_item company_ani">
                  <h2>喜讯！新闻资讯新闻资讯新闻资讯新闻资讯</h2>
                  <p>教育成就梦想，科技改变未来教育成就梦想，科技改变未来教育成就梦想，科技改变未来,教育成就梦想，科技改变未来教育成就梦想，科技改变未来教育成就梦想，科技改变未来。</p>
                  <div class="footer">
                    <span>2023.03.5</span>
                    <a href="javascript:;">MORE <i></i> </a>
                  </div>
                </div>
                <div class="five_item company_ani">
                  <h2>喜讯！新闻资讯新闻资讯新闻资讯新闻资讯</h2>
                  <p>教育成就梦想，科技改变未来教育成就梦想，科技改变未来教育成就梦想，科技改变未来,教育成就梦想，科技改变未来教育成就梦想，科技改变未来教育成就梦想，科技改变未来。</p>
                  <div class="footer">
                    <span>2023.03.5</span>
                    <a href="javascript:;">MORE <i></i> </a>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div ref="six" class="section module_home six_content" style="padding-bottom: 0;">
          <div class="jwsl">
            <div class="inner">
              <div class="header">
                <h2>杰文实力</h2>
                <p>JAVIN'S STRENGTH</p>
              </div>
            </div>
          </div>
          <div class="my_box">
            <div class="inner">
              <div class="my_box_sl">
                <div class="my_box_item" v-for="(item,index) in jwsl" :key="index">
                  <p class="num_box">
                    <span class="num">
                      <count-to :autoplay="false" :start-val="0" :end-val="item.num" :duration="3600" ref="num" />
                    </span>
                    <span class="eg">{{ item.eg }}</span>
                  </p>
                  <p class="txt">{{ item.txt }}</p>
                </div>
              </div>
              <div class="partner">
                <div class="header">
                  <h2>合作伙伴</h2>
                  <p>JAVIN'S STRENGTH</p>
                </div>
                <div class="partner_content">
                  <div style="display: flex">
                    <div class="content_inner">
                      <div class="partent_num">
                        <div>220+</div>
                        <p>院校深度合作，高职院校服务率超70% </p>
                      </div>
                    </div>
                    <div style="display: flex; flex-wrap: wrap; justify-content: space-between; width: 800px;margin-left: 10px;">
                      <img style="width: 170px;height: 124px;" :src="require(`./../assets/school/${item}.png`)" v-for="item in 8" :key="item">
                    </div>
                  </div>
                  <div style="display: flex; justify-content: space-between; width: 1200px;">
                    <div v-for="item in 14" :key="item" v-if="item>8">
                      <img style="width: 170px;height: 124px;" :src="require(`./../assets/school/${item}.png`)">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="about_us">
            <div class="inner">
              <div class="header">
                <h2>联系我们</h2>
                <p>CONTAC US</p>
              </div>
              <div class="about_content">
                <div class="us_item left" @click="handleAboutUs(0)">
                  <div class="bg1"></div>
                  <div class="bg2"></div>
                  <div class="text">
                    <div>
                      <div class="img_box"></div>
                      <p>在线留言</p>
                    </div>
                  </div>
                </div>
                <div class="us_item right" @click="handleAboutUs(1)">
                  <div class="bg1"></div>
                  <div class="bg2"></div>
                  <div class="text">
                    <div>
                      <div class="img_box"></div>
                      <p>加入我们</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footerbar></Footerbar>
        </div>
    <!-- </full-page> -->
        <BackTop></BackTop>
  </div>
</template>
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import Navbar from "@/components/Navbar"
import Sticky from "../components/Sticky"
import Footerbar from "@/components/Footerbar"
import CountTo from 'vue-count-to'
import BackTop from "@/components/BackTop"
import "animate.css";
import {getBannerList,getLishushili} from '@/api/home'
import { getNewsList } from '@/api/aboutus'
import {scrollTo} from '@/utils/scroll-to'
import { json } from "body-parser";
import { stringify } from "querystring";

import { animate } from "../mixin/animate"
export default {
  name: "Home",
  mixins: [ animate ],
  components: {
    Navbar,
    Sticky,
    swiper,
    swiperSlide,
    Footerbar,
    CountTo,
    BackTop
  },
  data() {
    return {
      baseUrl:process.env.VUE_APP_BASE_API,
      scrollProgress:0,
      options:{
				// 为每个section设置背景色
		        //sectionsColor: ["#f00","#0f0","#00f"],
		        //用来控制slide幻灯片的箭头，设置为false，两侧的箭头会消失
		        //controlArrows: false,
		        //每一页幻灯片的内容是否垂直居中
		        //verticalCentered: false,
		        //字体是否随着窗口缩放而缩放
		        //resize: true,
		        //页面滚动速度
		        //scrollingSpeed: 700,
		        //定义锚链接，用户可以快速打开定位到某一页面；不需要加"#"，不要和页面中任意的id和name相同
		        //anchors: ["page1","page2","page3"],
		        //是否锁定锚链接
		        //lockAnchors: true,
		        //定义section页面的滚动方式，需要引入jquery.easings插件
		        //easing:,
		        //是否使用css3 transform来实现滚动效果
		        //css3: false,
		        //滚动到最顶部后是否连续滚动到底部
		        //loopTop: true,
		        //滚动到最底部后是否连续滚动到顶部
		        //loopBottom: true,
		        //横向slide幻灯片是否循环滚动
		        //loopHorizontal: false,
		        //是否循环滚动，不会出现跳动，效果很平滑
		        //continuousVertical: true,
		        //是否使用插件滚动方式，设为false后，会出现浏览器自带的滚动条，将不会按页滚动
		        // autoScrolling: false,
		        //是否包含滚动条，设为true，则浏览器自带的滚动条会出现，页面还是按页滚动，但是浏览器滚动条默认行为也有效
		        // scrollBar: true,
		        //设置每个section顶部的padding，当我们要设置一个固定在顶部的菜单、导航、元素等时使用
		        //paddingTop: "100px",
		        //设置每个section底部的padding，当我们要设置一个固定在底部的菜单、导航、元素等时使用
		        //paddingBottom: "100px",
		        //固定的元素，为jquery选择器；可用于顶部导航等
		        //fixedElements: ".nav",
		        //是否可以使用键盘方向键导航
		        //keyboardScrolling: false,
		        //在移动设置中页面敏感性，最大为100，越大越难滑动
		        //touchSensitivity: 5,
		        //设为false，则通过锚链接定位到某个页面不再有动画效果
		        //animateAnchor: false,
		        //是否记录历史，可以通过浏览器的前进后退来导航
		        //recordHistory: true,
		        //绑定菜单，设定相关属性和anchors的值对应后，菜单可以控制幻灯片滚动
		        //menu: '.nav',
		        //是否显示导航，设为true会显示小圆点作为导航
		        //navigation: true,
		        //导航小圆点的位置，可以设置为left或者right
		        //navigationPosition: right,
		        //鼠标移动到小圆点上时显示出的提示信息
		        //navigationTooltips: ["第一页","第二页","第三页"],
		        //是否显示当前页面小圆点导航的提示信息，不需要鼠标移上
		        //showActiveTooltip: true,
		        //是否显示横向幻灯片的导航
		        //slidesNavigation: true,
		        //横向幻灯片导航的位置，可以为top或者bottom
		        //slidesNavPosition: bottom,
		        //内容超过满屏时是否显示滚动条，需要jquery.slimscroll插件
		        // scrollOverflow: true,
		        //section选择器
		        //sectionSelector: ".section",
		        //slide选择器
		        //slideSelector: ".slide"
                afterLoad: this.afterLoad,
                scrollOverflow: true,
                scrollBar: false,
                menu: '#menu',
                // navigation: true,
                // anchors: ['page1', 'page2', 'page3'],
                // sectionsColor: ['#41b883', '#ff5f45', '#0798ec', '#fec401', '#1bcee6', '#ee1a59', '#2c3e4f', '#ba5be9', '#b4b8ab']
            },
      bannerOption: {
        loop: true,
        autoplay: {
          delay:5000,
        },
        spaceBetween: 0,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      //  首页轮播
      bannerList: [],
      // abc
      isShow:false,
      rightActive:0,
      jwsl:[
        {
          num:12,
          eg:'年',
          txt:'教育信息化领域产业经验'
        },{
          num:100,
          eg:'人',
          txt:'创新团队占比80%'
        },{
          num:50,
          eg:'项',
          txt:'官方认证'
        },{
          num:200,
          eg:'所',
          txt:'高职院校服务率超70%'
        },{
          num:300,
          eg:'种',
          txt:'生态合作伙伴'
        },{
          num:50,
          eg:'项',
          txt:'大赛成绩'
        },
      ],
      tixiobg:{
        id:0,
        title:'杰文数字',
        subTitle:'深耕教育行业发展，引领数字教育创新模式',
        text:'数字教育是建立在知识层面之上、价值与能力并重的教育，发展目标是以教育数字化引领教育现代化，构建与数字时代相适应的全纳、公平、高质量、可持续的终身教育体系，培养全面化、自由化、个性化发展的数字时代新人。'
      },
      newsList:[],
      isTixiActive:'',
      tixiDolgPosition:{
        tixi0:0,
        tixi1:0,
        tixi2:0,
        tixi3:0,
        tixi4:0
      },
      flag:true,
      animateList: [{
        node: 'steam_ani',
        class: ()=>{
          return 'animate__flipInY'
        }
      },{
        node: 'total_ani',
        class: (index)=>{
          if ([0, 2, 3, 5].indexOf(index)>-1) {
           return 'animate__bounceInDown'
          } else {
            return 'animate__bounceInUp'
          }
        }
      },{
          node: 'cases_ani',
          class: (index)=>{
            switch(index) {
              case 0: return 'animate__bounceInLeft'
                break;
              case 1: return 'animate__bounceInRight'
                break;
            }
          }
        }, {
          node: 'company_ani',
          class: () => {
            return 'animate__flipInX'
          }
        }
      ]
    }
  },
  created() {
    this.getBannerList()
    this.getNewsList()
    this.getLishushili()
  },
  mounted() {
    // new WOW().init();
    this.$nextTick(()=>{
      this.scrollChange()
      window.addEventListener("scroll",this.scrollChange)
      window.addEventListener("scroll",this.handleNumStart)
      setTimeout(()=>{
        this.tixiDolgPosition.tixi0 = this.$refs.tixi0.offsetLeft
        this.tixiDolgPosition.tixi1 = this.$refs.tixi1.offsetLeft
        this.tixiDolgPosition.tixi2 = this.$refs.tixi2.offsetLeft
        this.tixiDolgPosition.tixi3 = this.$refs.tixi3.offsetLeft
        this.tixiDolgPosition.tixi4 = this.$refs.tixi4.offsetLeft
        console.log(this.$refs.tixi0.offsetLeft,this.$refs.tixi1.offsetLeft,this.$refs.tixi4.offsetLeft)
      },1000)
    })
    document.addEventListener('click',this.maopao)
  },
  destroyed(){
    document.removeEventListener('click',this.maopao)
    window.removeEventListener('scroll', this.scrollChange)
    window.removeEventListener('scroll', this.handleNumStart)
  },
  methods: {
    handleNextPage(){
      console.log(123)
      scrollTo(window.innerHeight,800)
    },
    scrollChange(){
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if((scrollTop/500)<0.5){
        this.scrollProgress = scrollTop/500
      }else{
        this.scrollProgress = 0.5
      }
    },
    afterLoad(origin, destination, direction){
        // origin 起点 destination终点 direction方向 固定写法
        // this.navIndex = destination.index;
        //destination.index代表对应页面index(从0开始)
        //拿到对应页面的index就可以进行操作
        if (destination.index > 0) {
          this.isShow = true;
        }else{
          this.isShow = false;
        }
        if(destination.index==5){
          this.$refs.num.forEach(v=>{
            v.start()
          })
        }
    },
    handleNumStart(){
      let fixOffset = this.$refs.six.offsetTop
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if(scrollTop+window.innerHeight>=fixOffset+80&&scrollTop<fixOffset+500+window.innerHeight&&this.flag){
        this.$refs.num.forEach(v=>{
          v.start()
        })
        this.flag = false
        console.log(123)
      }
    },
    handleFourClick(i){
      this.rightActive = i
    },
    handleTixiClick(i){
      this.isTixiActive = i
    },
    handleCloseTwoDlog(){
      this.isTixiActive = ''
    },
    maopao(){
      this.isTixiActive = ''
    },
    getBannerList(){
      getBannerList({
        pageSize:100,
        pageNum:1
      }).then(res=>{
        console.log(res)
        this.bannerList = res.rows
      })
    },
    // 跳转到解决方案
    handleShemeclick(i){
      this.$router.push({path:'/solution',query:{id:i}})
    },
    handleSuccessCases(i){
      this.$router.push({path:'/success-cases',query:{id:i}})
    },
    getNewsList(){
      getNewsList({
        pageSize:4,
        pageNum:1
      }).then(res=>{
        this.newsList = res.rows
      })
    },
    handleNewsItems(id){
      this.$router.push({name:"NewsItem",params:{id:id}})
    },
    handleAboutUs(i){
      this.$router.push({path:'/about-us',query:{id:i}})
    },
    getLishushili(){
      getLishushili().then(res=>{
        if(res.rows.length>0){
          const obj = res.rows[0]
          this.jwsl[0].num = obj.yearsNum*1
          this.jwsl[1].num = obj.teamNum*1
          this.jwsl[2].num = obj.renzhengNum*1
          this.jwsl[3].num = obj.universityNum*1
          this.jwsl[4].num = obj.partnerNum*1
          this.jwsl[5].num = obj.competitionNum*1
        }
      })
    }
  },
};
</script>
<style lang="scss" scoped >
.home{
  position: relative;
  overflow: hidden;
  min-height: 1000px;
  .module_home{
    padding-bottom: 64px;
    &:last-of-type{
      padding-bottom: 0px;
    }
  }
  .nav_bar{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    transition: all .3s;
  }
  .nav_down{
    background-color: rgba(5, 32, 63, .5);
  }
  .banner-next{
    z-index: 99;
    position: absolute;
    bottom: 30px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    cursor: pointer;
    span{
      position: relative;
      display: block;
      width: 26px;
      height: 70px;
      .mouse{
        animation: bounceY 2s infinite;
      }
      .arrows-1 {
        margin: auto;
          transform: rotate(90deg);
          left: 10px;
          top: 50px;
          animation: fade 2s infinite;
      }
      .arrows-2 {
        margin: auto;
          transform: rotate(90deg);
          left: 9px;
          top: 53px;
          animation: fade 2s infinite 1s;
      }
    }
  }
  @keyframes bounceY{
    from {     transform: translateY(0); }
    50% { transform: translateY(-15px); }
    to { transform: translateY(0); }
  }
  @keyframes fade{
    from { opacity: 0; }
    50% { opacity: 1; }
    to { opacity: 0; }
  }
  .section_first{
    height: 100vh;
    background-size: cover;
    width: 100%;
    background-position: center center;
  }
  .video{
    height: 100vh;
    width: 100%;
    object-fit: cover;
  }
  .twos_content{
    height: 100%;
    background: url('~@/assets/images/ywtx.png');
    background-size:  100% 100%;
    background-position-y:bottom;
    .header{
      text-align: center;
      padding-top: 85px;
      margin-bottom: 28px;
      h2{
        font-size: 32px;
        font-weight: bold;
        color: #005FC6;
        margin-bottom: 10px;
      }
      p{
        font-size: 16px;
        color: #999999;
      }
    }
    .inner{
      height: 100%;
    }
    .two_content{
      height: 100%;
      display: flex;
      justify-content: space-between;
      &>div{
        width: 20%;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        // height: 100%;
        height: 628px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:nth-of-type(1){
          background-image: url('~@/assets/images/ywtx/1.png');
        }
        &:nth-of-type(2){
          background-image: url('~@/assets/images/ywtx/2.png');

        }
        &:nth-of-type(3){
          background-image: url('~@/assets/images/ywtx/3.png');

        }
        &:nth-of-type(4){
          background-image: url('~@/assets/images/ywtx/4.png');

        }
        &:nth-of-type(5){
          background-image: url('~@/assets/images/ywtx/5.png');

        }
        .countent_inner{
          text-align: center;
          color: #fff;
          padding-bottom: 50px;
          img{
            margin-bottom: 78px;
          }
          p{
            font-size: 24px;
            font-weight: bold;
          }
        }
      }
      position: relative;
    }
    .box{
      height: calc(100% - 249px);
      position: relative;
      .transition{
        position: absolute;
        right: -300px;
        bottom: -37px;
        z-index: 4;
        width: 823px;
        transition: all .3s;
        z-index:-1;
        opacity: 0;
        &.lastImg{
          width: 600px;
          right: -100px;
          bottom: 0px;
          animation: fagbig 2s infinite;
        }
        &.transition_active{
          z-index:4;
          opacity: 1;
        }
      }
      .two_dlog{
        position: absolute;
        width: 240px;
        overflow: hidden;
        opacity: 0;
        z-index: -1;
        // top: 0;
        // left: 0;
        // width: 100%;
        height: 100%;
        background: url('~@/assets/images/ywtxopen.png');
        background-size: cover;
        // display: block;
        padding: 12px 20px;
        text-align: left;
        ::v-deep .el-icon-back{
          color: #fff;
          font-size: 16px;
        }
        .inner_box{
          width: 1200px;
        }
        .tow_dlogcontent{
          width: 45%;
          color: #fff;
          padding-left: 60px;
        }
        .header{
          text-align: left;
          position: relative;
          padding-top: 65px;
          .title{
            font-size: 34px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #EFBC48;
            margin-bottom: 12px;
          }
          .sub_title{
            font-size: 20px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
            padding-bottom: 20px;
          }
          margin-bottom: 32px;
          &::after{
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background: linear-gradient(to right,#FFFFFF, rgba(255, 255, 255, 0));
          }
        }
        .text{
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 2;
          height: 200px;
        }
        .btn_box{
          display:flex;
          justify-content:space-between;
          margin-top: 10px;
          a{
            border: 1px solid #FFFFFF;
            border-radius: 10px;
            color:#fff;
            padding: 9px 51px;
            line-height: 1;
            transition: all .3s;
            &:hover{
              background-color: #EFBC48;
              border: 1px solid #EFBC48;
            }
          }
        }
        transition: all .3s;
      }
      .tixi_active{
        display: block;
        // transition: all .3s;
        opacity: 1;
        z-index: 2;
        left: 0!important;
        width: 100%;
      }
    }
  }
  @keyframes fagbig{
    from { transform: scale(1,1); }
    50% { transform: scale(1.05,1.05); }
    to { transform: translateY(1,1); }
  }
  .three_content{
    background: url('~@/assets/images/jvfa.png');
    background-size:  100% 100%;
    background-position-y:bottom;
    .inner{
      height: 100%;
      padding-top: 59px;
      .header{
        text-align: center;
        color: #fff;
        margin-bottom: 40px;
        h2{
          margin-bottom: 10px;
          font-size: 32px;
          font-weight: bold;
        }
      }
    }
  }
  .three_content_inner{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    li{
      overflow: hidden;
      width: 390px;
      height: 293px;
      margin-bottom: 10px;
      border-radius: 10px;
      cursor: pointer;
      .bottom,.top{
        height: 100%;
        width: 100%;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        transition: all .3s;
      }
      .top{
        color: #fff;
        font-size: 24px;
        font-weight: bold;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        padding-left: 39px;
        line-height: 1.5;
      }
      .bottom{
        color: #fff;
        padding-top: 70px;
        h2{
          font-size: 18px;
          font-weight: bold;
          color: #FFFFFF;
          padding-bottom: 9px;
          position: relative;
          text-align: center;
          margin-bottom: 35px;
          &::after{
            content: '';
            width: 33px;
            height: 4px;
            background: #FFFFFF;
            border-radius: 2px;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
          }
        }
        p{
          padding: 0 60px;
          line-height: 1.5;
        }
      }
      &:nth-of-type(1):hover .top{
        transform: translateY(-293px);
      }
      &:nth-of-type(2):hover .top{
        transform: translateY(-293px);
      }
      &:nth-of-type(3):hover .top{
        transform: translateY(-293px);
      }
      &:nth-of-type(4):hover .top{
        transform: translateY(-293px);
      }
      &:nth-of-type(5):hover .top{
        transform: translateY(-293px);
      }
      &:nth-of-type(1) .top{
        background-image: url('~@/assets/images/jvfa/1.png');
      }
      &:nth-of-type(2) .top{
        background-image: url('~@/assets/images/jvfa/2.png');
      }
      &:nth-of-type(3) .top{
        background-image: url('~@/assets/images/jvfa/3.png');
      }
      &:nth-of-type(4) .top{
        background-image: url('~@/assets/images/jvfa/4.png');
      }
      &:nth-of-type(5) .top{
        background-image: url('~@/assets/images/jvfa/5.png');
      }
      &:nth-of-type(6) .top{
        background-image: url('~@/assets/images/jvfa/6.png');
      }
      &:nth-of-type(1) .bottom{
        background-image: url('~@/assets/images/jvfa/1-1.png');
      }
      &:nth-of-type(2) .bottom{
        background-image: url('~@/assets/images/jvfa/2-1.png');
      }
      &:nth-of-type(3) .bottom{
        background-image: url('~@/assets/images/jvfa/3-1.png');
      }
      &:nth-of-type(4) .bottom{
        background-image: url('~@/assets/images/jvfa/4-1.png');
      }
      &:nth-of-type(5) .bottom{
        background-image: url('~@/assets/images/jvfa/5-1.png');
      }
      &:nth-of-type(1):hover .bottom{
        transform: translateY(-293px);
      }
      &:nth-of-type(2):hover .bottom{
        transform: translateY(-293px);
      }
      &:nth-of-type(3):hover .bottom{
        transform: translateY(-293px);
      }
      &:nth-of-type(4):hover .bottom{
        transform: translateY(-293px);
      }
      &:nth-of-type(5):hover .bottom{
        transform: translateY(-293px);
      }
    }
    .last{
      a{
        color: #fff;
      }
    }
    .last:hover{
      a{
        text-decoration: underline;
      }
    }
  }
  .four_content{
    height: 100%;
    background: url('~@/assets/images/ywtx.png');
    background-size:  100% 100%;
    background-position-y:bottom;
    .inner{
      height: 100%;
      padding-top: 59px;
    }
    .header{
      text-align: center;
      margin-bottom: 28px;
      h2{
        font-size: 32px;
        font-weight: bold;
        color: #005FC6;
        margin-bottom: 10px;
      }
      p{
        font-size: 16px;
        color: #999999;
      }
    }
    .four_content_inner{
      height: 640px;
      background: linear-gradient(0deg, #FFFFFF, #E4F3FE);
      box-shadow: 0px 0px 15px #a9a9a98a;
      border-radius: 10px;
      display: flex;
      overflow: hidden;

      .item{
        height: 100%;
        .logoList {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          width: 569px;
          background-color: #FFFFFF;
          border-radius: 10px;
          padding: 10px;
        }


        &.left{
          padding-top: 20px;
          border-radius: 10px;
          width: 492px;
          background: url('~@/assets/images/cgal.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;

          .sub_item{
            padding: 0px 40px;
            color: #fff;
            cursor: pointer;

            &.left_active{
              background-color: #fff;
              .sub_inner{
                color: #005FC6;

                h4{
                  color: #005FC6;
                  font-size:24px!important;
                }
                p{
                  font-size: 18px!important;
                }
              }
            }
            &:hover{
              .sub_inner{
                h4{
                  font-size: 26px;
                }
                p{
                  font-size:20px;
                }
              }
            }
            .sub_inner{
              padding: 22px 0;
              display: flex;
              border-bottom: 1px solid #FFF;
              align-items: center;
              height: 120px;
              h4{
                font-weight: bold;
                color: #FEFEFE;
                line-height: 1;
                margin-bottom: 8px;
                font-size: 24px;
                // transition: all .2s;
              }
              p{
                font-size: 18px;
                // transition: all .2s;
              }
              .image_box{
                margin-right: 29px;
                width: 59px;
                img{
                  margin: auto;
                }
              }
            }
            &:last-of-type .sub_inner{
              border-bottom: none;
            }
          }
        }
        &.right{
          position: relative;
          flex: 1;
          overflow: hidden;
          .right_item{
            padding: 60px 60px 0 79px;
            width: 100%;
            position: absolute;
            transform: translateX(-100%);
            opacity: 0;
            &.right_active{
              transform: translateX(0%);
              transition: all .3s;
              opacity: 1;
            }
            h3{
              font-size: 24px;
              font-weight: bold;
              color: #005FC6;
              padding-bottom: 10px;
              margin-bottom: 20px;
              position:relative;
              &::after{
                content: '';
                position:absolute;
                left: 0;
                bottom: 0;
                width: 289px;
                height: 1px;
                background: linear-gradient(to right, #005FC6,#0060c600);
              }
            }
            p{
              line-height: 1.5;
              margin-bottom: 24px;
            }
            a{
              width: 111px;
              height: 34px;
              border: 1px solid #005FC6;
              border-radius: 17px;
              display: block;
              line-height: 32px;
              text-align: center;
              color: #005FC6;
              font-size: 14px;
              margin-bottom: 30px;
              transition: all .3s;
              &:hover{
                background-color: #005FC6;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
  .five_content{
    background: url('~@/assets/images/qydt.png');
    background-size:  100% 100%;
    background-position-y:bottom;
    .inner{
      height: 100%;
      padding-top: 59px;
    }
    .header{
      text-align: center;
      margin-bottom: 28px;
      h2{
        font-size: 32px;
        font-weight: bold;
        color: #005FC6;
        margin-bottom: 10px;
      }
      p{
        font-size: 16px;
        color: #999999;
      }
    }
    .five_content_inner{
      display: flex;
      .left{
        width: 45%;
        .img_box{
          margin-bottom: 20px;
        }
        h2{
          text-align: center;
        }
      }
      .right{
        width: 55%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 50px;
        .five_item{
          padding: 20px 30px;
        }
        .footer{
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 12px;
          a,span{
            color: #999999;
            transition: all .3s;
          }
          i{
            display: inline-block;
            height: 14px;
            width: 15px;
            background: url('~@/assets/images/btn.png');
          }
        }
      }
      .five_item{
        padding: 30px;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0px 0px 10px #00000057;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        p{
          ::v-deep{
            a,h1,h2,h3,h4,h5,h6,div,p,span{
              font-size: 16px!important;
            }
          }
          
        }
        &::after{
          content: ' ';
          position: absolute;
          height: 2px;
          width: 0;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          background: #005FC6;
          transition: all .3s;
        }
        h2{
          font-size: 20px;
          font-weight: bold;
          color: #333333;
          margin-bottom: 18px;
          transition: all .3s;
        }
        p{
          height: 61px;
          overflow: hidden;
          word-break: break-all;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        &:hover{
          h2{
            color: #005FC6;
          }
          .footer{
            span{
              color: #005FC6;
            }
            a{
              color: #005FC6;
            }
            i{
              background: url('~@/assets/images/btna.png');
            }
          }
          &::after{
            width:100%;
          }
        }
      }
    }
  }
  .six_content{
    .jwsl{
      color: #fff;
      display: flex;
      background: url("~@/assets/images/jwsl.png");
      background-size: cover;
      .inner{
        padding: 59px 0;
        padding-bottom: 160px;
      }
      .header{
        text-align: center;
        h2{
          margin-bottom: 10px;
          font-size: 32px;
          font-weight: bold;
        }
      }
    }
    .my_box_sl{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      height: 386px;
      box-shadow: 0 0 15px #0000005e;
      border-radius: 10px;
      position: relative;
      top: -100px;
      background-color: #fff;
      padding: 30px;
      background: url('~@/assets/images/my_box_sl.png');
      background-size: cover;
      .my_box_item{
        width: 33.33%;
        text-align: center;
        font-weight: bold;
        border-right: 1px solid #999;
        &:nth-of-type(3n){
          border-right: none;
        }
        .num{
          font-size: 54px;
          color: #005FC6;
          padding-right: 10px;
          // font
          position: relative;
          &::after{
            content: '+';
            position: absolute;
            top: 4px;
            right: 4px;
            font-size: 16px;
          }
        }
        .txt{
          margin-top: 5px;
        }
      }
    }
    .my_box{
      background: url('~@/assets/images/hzhb.png');
      .partner{
        margin-top: -22px;
        padding-bottom: 79px;
        .header{
          text-align: center;
          margin-bottom: 28px;
          h2{
            font-size: 32px;
            font-weight: bold;
            color: #005FC6;
            margin-bottom: 10px;
          }
          p{
            font-size: 16px;
            color: #999999;
          }
        }
      }
      .partner_content{
        height: 434px;
        // background:url('~@/assets/images/partent.png');
        .content_inner{
          width: 405px;
          height: 273px;
          background-color: #000;
          border-radius: 10px;
          color: #F3F9FF;
          display: flex;
          justify-content: center;
          align-items: center;
          background: url('~@/assets/images/partent_inner.png');
        }
        .partent_num{
          text-align: center;
          div{
            font-size: 87px;
            font-weight: bold;
          }
          p{
            font-size: 21px;
          }
        }

        img:hover {
          transform:scale(1.1,1.1);
          transition:Linear 0.3s
        }
      }

    }
    .about_us{
        padding: 59px 0 75px;
        .header{
          text-align: center;
          margin-bottom: 28px;
          h2{
            font-size: 32px;
            font-weight: bold;
            color: #005FC6;
            margin-bottom: 10px;
          }
          p{
            font-size: 16px;
            color: #999999;
          }
        }
        .about_content{
          display: flex;
          justify-content: space-between;
          .us_item{
            width: 50%;
            border-radius: 10px;
            position: relative;
            width: 590px;
            height: 240px;
            overflow: hidden;
            .bg1,.bg2,.text{
              position: absolute;
              left:0;
              top: 0;
              width: 100%;
              height: 100%;
            }
            .bg1{
              transition: all .3s;
              background: url('~@/assets/images/liuyan.png');
              background-size: cover;
              opacity: 1;
            }
            .bg2{
              transition: all .3s;
              background: url('~@/assets/images/liuyanhover.png');
              background-size: cover;
              z-index: 2;
              opacity: 0;
            }
            .text{
              transition: all .3s;
              z-index: 3;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 24px;
              font-weight: bold;
              color: #FFFFFF;
              text-align: center;
              cursor: pointer;
              .img_box{
                height: 128px;
                width:128px;
                background: url('~@/assets/images/us_baise.png');
              }
            }
            &:last-of-type{
              .text .img_box{
                background: url('~@/assets/images/liuyan_baise.png');
              }
            }
            &:hover{
              .bg1{
                opacity: 0;
              }
              .bg2{
                opacity: 1;
              }
              .text{
                color: #333;
                .img_box{
                  background: url('~@/assets/images/us_lanse.png');
                }
              }
              &:last-of-type{
                .text .img_box{
                  background: url('~@/assets/images/liuyan_lanse.png');
                }
              }
            }
          }
        }
    }
  }
}
</style>
