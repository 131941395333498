<template>
    <!-- <div class="nav_list">
        <el-menu router :default-active="$route.path" class="dazy_menu" mode="horizontal" active-text-color="#FE0100">
            <el-menu-item index="/home">网站首页</el-menu-item>
            <el-menu-item index="/come-in-JW">大赛资讯</el-menu-item>
            <el-menu-item index="/solution">资源中心</el-menu-item>
            <el-menu-item index="/success-cases">参赛报名</el-menu-item>
            <el-menu-item index="/company-news">大赛荣誉</el-menu-item>
            <el-menu-item index="/about-us">联系我们</el-menu-item>
        </el-menu>
        <el-input
        size="small"
        class="search_input"
        placeholder="请输入比赛资讯"
        prefix-icon="el-icon-search"
        v-model="serachPath">
    </el-input>
    </div> -->
</template>

<script>
    export default {
        name:'NavList',
        data(){
            return {
                serachPath:''
            }
        },
    }
</script>

<style lang="scss" scoped>
.nav_list{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .search_input{
        width:275px;
        ::v-deep input{
            border-radius: 30px;
        }
    }
}
.dazy_menu::v-deep.el-menu.el-menu--horizontal{
    border: none;
    .el-menu-item{
        font-size: 16px;
        height: 46px;
        line-height: 46px;
        color: #333;
        &:hover{
            color: #FE0100;
        }
    }
}
</style>