export const animate = {
  data() {
    return {
      animateList: []
    };
  },
  computed: {},
  created() {

  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.animateListHandle)
    })
  },
  destroyed() {
    window.removeEventListener('scroll', this.animateListHandle)
  },
  methods: {
    animateListHandle() {
      this.animateList.map(item=>{
        this.scrollIsActiveHandle(item.node, item.class)
      })
    },

    scrollIsActiveHandle(item, method) {
      let windowHeight = document.documentElement.clientHeight
      let dom = document.getElementsByClassName([item])

      if (JSON.stringify(dom) === '{}') return
      let top = dom[0].getBoundingClientRect().top
      if (top == 193 || top == 0) return;
      if (this.scrollIsActive(windowHeight, top)) { // 判断显示距离
        const nodeList = document.getElementsByClassName(item);
        Object.keys(nodeList).forEach((key, index) => {
          let node = nodeList[key]
          if (!!this.hasClass(node, 'actived')) return
          this.animateCSS(node, method(index))
        })
      }
    },
    animateCSS(node, animation, prefix = 'animate__') {
      new Promise((resolve, reject) => {
        node.classList.add(`${prefix}animated`, animation, 'actived');
        node.addEventListener('animationend', handleAnimationEnd, {once: true});

        // 动画结束后清理类
        function handleAnimationEnd(event) {
          event.stopPropagation();
          node.classList.remove(`${prefix}animated`);
          resolve('Animation ended');
        }
      })
    },

    scrollIsActive(windowHeight, targetTop) {
      var lock = false
      console.log('targetTop', targetTop, windowHeight*0.7)
      // 如果目标元素离页面顶端的距离小于视窗高度+滚动条离页面顶端的距离
      // 则表示已滚动到目标元素位置
      if(lock) {
        return
      } else {
        if(targetTop < (windowHeight*0.5)) {
          lock = true
          return true
        }
      }
    },

    hasClass(element, cls) {
      return ('' + element.className + '').indexOf('' + cls + '') > -1;
    },
  },
};
