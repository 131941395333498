import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './style/index.scss'
import 'animate.css'

import './permission' // permission control
import modal from './utils/modal'
import 'fullpage.js/vendors/scrolloverflow';
import VueFullPage from 'vue-fullpage.js';
Vue.prototype.$modal = modal
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueFullPage);
//预览地址
new Vue({    
    router,
    render: h => h(App)
}).$mount('#app')