import request from '@/utils/request'
export function getDict(query) {
    return request({
      url: '/system/dict/data/type/liuyanleixing',
      method: 'get',
      params: query,
      isToken:false
    })
  }
    //添加留言信息   
  export function postLiuyan(data) {
    return request({
      url: '/business/liuyan',
      method: 'post',
      data,
      isToken:false
    })
  }
// 新闻列表
export function getNewsList(query) {
    return request({
      url: '/business/zixun/list',
      method: 'get',
      params: query,
      isToken:false
    })
  }
// 获取新闻详情
export function getNewsDetails(id) {
    return request({
      url: '/business/zixun/' + id,
      method: 'get',
      isToken:false
    })
  }