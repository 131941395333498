export function calSize(fileSize){
      return fileSize==null?"无":fileSize<1024?fileSize+"B":
      fileSize<1024*1024? Math.ceil(fileSize/1024)+"K":fileSize<1024*1024*1024?
      Math.ceil(fileSize/1024/1024)+"M":Math.ceil(fileSize/1024/1024/1024)+"G"
    }

/**
 * Parse the time to string
 * @param {new Date 对象} date
 * @param {string} 输出规则 Format
 * @returns {string | null}
 */
export function parseTime(date,Format){
    let o = {
      "M+": date.getMonth() + 1,                    // getMonth方法，月份从0开始的
      "d+": date.getDate(),                         // 获得天数
      "H+": date.getHours(),                        // 获得小时，24制
      "h+": date.getHours() > 12 ? date.getHours() - 12 : date.getHours(),  // 获得小时，12制
      "m+": date.getMinutes(),                      // 获得分钟
      "s+": date.getSeconds(),                      // 获得秒数，3位数
      "q+": Math.floor((date.getMonth() + 3) / 3),  // 获得季度
      "f+": date.getMilliseconds(),                 // 获得毫秒
    };
    
    if(/(y+)/.test(Format)) {						// 获得年份
      Format = Format.replace(RegExp.$1, date.getFullYear() + "").substr(4 - RegExp.$1.length);
      // RegExp.$1是yyyy（或者yy）的字符串
      // substr方法目的为：当年份是yy时截取从2开始到最后面的字符串，这样年份就变成了2位
      // 				   当年份是yyyy时截取从0开始到最后面的字符串，这样年份就变成了4位
    }
    
    for(let k in o) {								// 获得其他的信息
      if(new RegExp(`(${k})`).test(Format)) {		// 使用字符串模板在字符串里面解析变量
        Format = Format.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        // (("00" + o[k]).substr(("" + o[k]).length)))的目的为给天数、小时、分钟、秒数、季度的添0，毫秒无法添0
      }
    }
    return Format;
}
export function parseDate(time, pattern) {
	if (arguments.length === 0 || !time) {
		return null
	}
	const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
	let date
	if (typeof time === 'object') {
		date = time
	} else {
		if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
			time = parseInt(time)
		} else if (typeof time === 'string') {
			time = time.replace(new RegExp(/-/gm), '/').replace('T', ' ').replace(new RegExp(/\.[\d]{3}/gm),'');
		}
		if ((typeof time === 'number') && (time.toString().length === 10)) {
			time = time * 1000
		}
		date = new Date(time)
	}
	const formatObj = {
		y: date.getFullYear(),
		m: date.getMonth() + 1,
		d: date.getDate(),
		h: date.getHours(),
		i: date.getMinutes(),
		s: date.getSeconds(),
		a: date.getDay()
	}
	const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
		let value = formatObj[key]
		// Note: getDay() returns 0 on Sunday
		if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
		if (result.length > 0 && value < 10) {
			value = '0' + value
		}
		return value || 0
	})
	return time_str
}
function getBlength(str) {
  for (var i = str.length, n = 0; i--; ) {
      n += str.charCodeAt(i) > 255 ? 2 : 1;
  }
  return n;
}
function cutByte(str, len, endstr) {
  var len = +len,
  endstr = typeof(endstr) == 'undefined' ? "..." : endstr.toString(),
  endstrBl = getBlength(endstr);
  function n2(a) {var n = a / 2 | 0; return (n > 0 ? n : 1)}//用于二分法查找
  if (!(str + "").length || !len || len <= 0) {
      return "";
  }
  if(len<endstrBl){
      endstr = "";
      endstrBl = 0;
  }
  var lenS = len - endstrBl,
  _lenS = 0,
  _strl = 0;
  while (_strl <= lenS) {
      var _lenS1 = n2(lenS - _strl),
      addn = getBlength(str.substr(_lenS, _lenS1));
      if (addn == 0) {return str;}
      _strl += addn
      _lenS += _lenS1
  }
  if(str.length - _lenS > endstrBl || getBlength(str.substring(_lenS-1))>endstrBl){
      return str.substr(0, _lenS - 1) + endstr
  }else{
      return str;
  }
}
export function wordlimit(text, wordlength) {
  // 判断字符串字节数是否小于等于wordlength，小于等于就不用截取，直接显示，大于wordlength就截取
  if (getBlength(text) <= wordlength) {
      return text;
  } else {
      // 截取指定长度的字符串
      var result = cutByte(text, wordlength, '');
      // alert(result);
      return result + '...';
  }
}
/**
* 参数处理
* @param {*} params  参数
*/
export function tansParams(params) {
	let result = ''
	for (const propName of Object.keys(params)) {
		const value = params[propName];
		var part = encodeURIComponent(propName) + "=";
		if (value !== null && typeof (value) !== "undefined") {
			if (typeof value === 'object') {
				for (const key of Object.keys(value)) {
					if (value[key] !== null && typeof (value[key]) !== 'undefined') {
						let params = propName + '[' + key + ']';
						var subPart = encodeURIComponent(params) + "=";
						result += subPart + encodeURIComponent(value[key]) + "&";
					}
				}
			} else {
				result += part + encodeURIComponent(value) + "&";
			}
		}
	}
	return result
}
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function() {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function(...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}
// 2022-11-15T10:14:45.000+08:00 格式转化为时分秒
export function timezhuan(time){
	if (!time) {
		return null
	}
	var timepl = time;
	timepl = timepl.replace(/-/, '年')
	timepl = timepl.replace(/-/, '月')
	timepl = timepl.replace(/T/, '日')
	let times = timepl.split('.')
	return times[0];
}