<template>
    <div class="layouts">
      <navbar class="fixed_nav"  :style="{background: `rgba(5, 32, 63, ${scrollProgress})`}" ></navbar>
      <router-view></router-view>
      <footerbar></footerbar>
      <BackTop></BackTop>
    </div>
</template>
<script>
import Navbar from "@/components/Navbar"
import Footerbar from "@/components/Footerbar"
// import { getCompany } from '@/api/user'
import Sticky from "@/components/Sticky"
import BackTop from "@/components/BackTop"
export default {
    name:'Layout',
    data(){
        return{
          company:{},
          scrollProgress:0
        }
    },
    components:{
      Navbar,Footerbar,Sticky,BackTop
    },
    created(){
      // getCompany().then(res=>{
      //   this.company = res.data
      //   this.$store.commit('user/SET_ABOUTUS',this.company.companyAbout)
      //   this.$store.commit('user/SET_BANNER',{banner1:this.company.companyBanner1,banner2:this.company.companyBanner2})
      //   this.$store.commit('user/SET_COMPANY',this.company)
      // })
    },
    destroyed(){
      window.removeEventListener('scroll', this.scrollChange)
    },
    mounted(){
      this.scrollChange()
      window.addEventListener("scroll",this.scrollChange)
    },
    methods:{
      scrollChange(){
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        if((scrollTop/500)<0.5){
          this.scrollProgress = scrollTop/500
        }else{
          this.scrollProgress = 0.5
        }
      }
    }
}
</script>
<style scoped lang="scss">
.fixed_nav{
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  min-width: 1200px;
}
</style>
