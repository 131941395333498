import router from './router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
NProgress.configure({ showSpinner: false })
router.beforeEach((to, from, next) => {
    NProgress.start()
    next()
    // if (getToken()) {
    //     if (!store.getters.userId){
    //         store.dispatch('user/getInfo').then(()=>{
    //             next({ ...to, replace: true })
    //         }).catch(()=>{
    //             store.dispatch('user/logout').then(err => {
    //                 next({ path: '/' })
    //             })
    //         })
    //     }else{
    //         next()
    //     }
    // }else{
    //     if(to.meta.required){
    //         NProgress.done()
    //         next(false)
    //         login.install({loginSuccess() {next("/redirect" + to.fullPath)}})
    //     }else{
    //         next()
    //     }
        
    // }
})

router.afterEach(() => {
    NProgress.done()
})