<template>
  <div class="navbar">
    <router-link to="/">
      <img src="~@/assets/images/long_logo.png" alt="杰文logo">
    </router-link>
    <div>
      <el-menu router :default-active="$route.path" class="jw_menu" mode="horizontal">
          <el-menu-item index="/">首页</el-menu-item>
          <el-menu-item index="/come-in-JW">走进杰文</el-menu-item>
          <el-menu-item index="/solution">解决方案</el-menu-item>
          <el-menu-item index="/success-cases">成功案例</el-menu-item>
          <el-menu-item index="/resource-lib">灵犀蓝图</el-menu-item>
          <el-menu-item index="/company-news">企业动态</el-menu-item>
          <el-menu-item index="/about-us">联系我们</el-menu-item>
      </el-menu>
    </div>
  </div>
</template>
<script>
// import Sticky from "@/components/Sticky"
import NavList from "./NavList.vue"
export default {
  name: "NavBar",
  components: {
    // Sticky,
    NavList
  },
  props: {},
  data() {
    return {

    };
  },
  created() {

  },
  mounted() {},
  watch: {

  },
  methods: {

  },
};
</script>
<style lang="scss" scoped>
.navbar{
  min-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 60px;
  height: 80px;
  overflow: hidden;
  ::v-deep {
    .el-menu{
      border: none;
      background-color: transparent;
    }
    .el-menu--horizontal>.el-menu-item{
      line-height: 80px;
      height: 80px;
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      &.hover{
        color: #fff;
        background-color: transparent;
      }
    }
    .el-menu--horizontal .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal .el-menu-item:not(.is-disabled):hover{
      color: #fff;
        background-color: transparent;
    }
    .el-menu--horizontal>.el-menu-item.is-active{
      border-bottom: transparent;
      background-color: #CADDF3!important;
      color: #005FC6!important;
    }
  }
  .jw_menu{
    .el-menu-item{
      margin-left: 50px;
    }
  }
}
@media only screen and (max-width: 1470px) {
  .navbar .jw_menu ::v-deep .el-menu-item {
        margin-left: 20px;
    }
}
@media only screen and (max-width: 1300px) {
  .navbar .jw_menu ::v-deep .el-menu-item {
        margin-left: 10px;
    }
}

</style>
