import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Layout from '../layout'
Vue.use(VueRouter)

const routes = [{
            path: "/",
            name: 'home',
            component: Home
        },{
        path: '/layout',
        name: 'Layout',
        // redirect: '/home',
        component: Layout,
        children: [{
                path: "/come-in-JW",
                name: 'ComeInJW',
                component: () => import('@/views/ComeInJW'),
            },{
                path: "/solution",
                name: 'Solution',
                component: () => import('@/views/Solution'),
            },{
                path: "/success-cases",
                name: 'SuccessCases',
                component: () => import('@/views/SuccessCases'),
            },{
                path: "/company-news",
                name: 'CompanyNews',
                component: () => import('@/views/CompanyNews'),
            },{
                path: "/news-item/:id",
                name: 'NewsItem',
                component: () => import('@/views/NewsItem'),
            },{
              path: "/resource-lib",
              name: 'ResourceLibCenter',
              component: () => import('@/views/ResourceLibCenter'),
            },{
                path: "/about-us",
                name: 'AboutUS',
                component: () => import('@/views/AboutUS'),
            },{
                path: '/redirect/:path(.*)',
                name: 'redirect',
                component: (resolve) => require(['@/views/redirect'], resolve)
            },
        ]
    },
]
const router = new VueRouter({
    // mode: 'history', //关闭history 模式
    base: process.env.BASE_URL,
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
        // return 期望滚动到哪个的位置
    },
    routes
})

export default router
