<template>
  <footer class="footer">
    <div class="inner">
      <div class="footer_content clearfix">
        <div class="left lt">
          <div class="item lt" @click="handlePath('/come-in-JW')">
            <div class="title" >走进杰文</div>
            <div class="sub_title">公司介绍</div>
            <div class="sub_title">企业文化</div>
            <div class="sub_title">发展历程</div>
            <div class="sub_title">业务体系</div>
          </div>
          <div class="item lt" @click="handlePath('/solution')">
            <div class="title" >解决方案</div>
            <div class="sub_title">杰文数字</div>
            <div class="sub_title">杰文智能</div>
            <div class="sub_title">杰文仿真</div>
            <div class="sub_title">杰文软件</div>
            <div class="sub_title">杰文学院</div>
          </div>
          <div class="item lt" @click="handlePath('/success-cases')">
            <div class="title" >成功案例</div>
            <div class="sub_title">杰文数字</div>
            <div class="sub_title">杰文智能</div>
            <div class="sub_title">杰文仿真</div>
            <div class="sub_title">杰文软件</div>
            <div class="sub_title">杰文学院</div>
          </div>
          <div class="item lt" @click="handlePath('/company-news')">
            <div class="title" >企业动态</div>
            <div class="sub_title">行业新闻</div>
            <div class="sub_title">企业动态</div>
          </div>
          <div class="item lt" @click="handlePath('/about-us')">
            <div class="title" >联系我们</div>
            <div class="sub_title">地址：郑州市经开区第九大街经南二路正商经开广场6号楼3层</div>
            <div class="sub_title">电话：0371-56062765</div>
            <div class="sub_title">邮箱：mis01@qq.com</div>
          </div>
        </div>
        <div class="right rt">
          <div class="logo_box">
            <img src="~@/assets/images/logo2.png" alt="">
          </div>
          <div class="gzh_box">
            <img src="~@/assets/images/gzh.png" alt="">
            <p>微信公众号</p>
          </div>
        </div>
      </div>

      <p class="beian">
        <img src="~@/assets/beian.png" alt="">
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41019602002324" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="" style="float:left;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#fff;margin-right: 5px;">豫公网安备 41019602002324号</p></a>
        豫ICP备12011404号-2 河南杰文信息技术有限公司版权所有 版本号：V2.1.0
      </p>
    </div>
  </footer>
</template>
<script>
export default {
  data(){
    return {
      serachPath:''
    }
  },
  methods:{
    handlePath(path){
      if(this.$route.path!=path){
        this.$router.push({path})
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.footer{
  background: url("~@/assets/images/footer.png");
  padding-top: 38px;
  .footer_content{
    padding-bottom: 60px;
    border-bottom: 1px solid #fff;
    color: #fff;
  }
  .left{
    .item{
      .title{
        padding: 21px 0 30px;
        font-size: 18px;
        font-weight: bold;
        cursor: pointer;
      }
      .sub_title{
        margin-bottom: 20px;
        font-size: 14px;
        cursor: pointer;
        &:hover{
          text-decoration: underline;
        }
      }
      margin-left: 78px;
      &:first-of-type{
        margin-left: 0;
      }
    }
  }
  .right{
    text-align: center;
    img{
      display: block;
      margin: auto;
    }
    .logo_box{
      margin-bottom: 30px;
    }
    .gzh_box{
      p{
        margin-top: 10px;
      }
    }
  }
  .beian{
    color: #fff;
    padding:36px 0;
    text-align: center;
    font-size: 14px;
    display: flex;
    justify-content: center;
  }
}
</style>
