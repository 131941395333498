<template>
  <div id="app">
    <router-view/>
    <!-- <global-uploader></global-uploader> -->
  </div>
</template>
<script>
    export default {
        name: 'app',
        data() {
            return {}
        }
    }
</script>
<style lang="scss">
#app{
  background: #fff;
}
.animate__delay-500ms {
  --animate-delay: 500ms;
}
.animate__delay-1500ms {
  --animate-delay: 1500ms;
}
.animate__delay-2500ms {
  --animate-delay: 2500ms;
}
</style>
